// src/components/Header/Header.js
import React from 'react';
import './footer.css';

function Footer() {
  return (
    <footer>
        <div className="row max-width">
            <div className="col-md-4">
                <h4>Contato</h4>
                <p>Telefone: (21) 99042-6814</p>
            </div>

            <div className="col-md-4">
                <h4>Endereço</h4>
                <p>Avenida Amaro Cavalcanti, 1701</p>
                <p>Engenho de Dentro, Rio de Janeiro</p>
                <p>CEP: 20735-042</p>
            </div>

            <div className="col-md-4">
                <h4>Localização</h4>
                <iframe id="googleMap" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAXNwqVWZUuXnumZE28qFlXKSZ5lmAow4o
            &q=Avenida+Amaro+cavalcanti+1701+Engenho+de+dentro" frameBorder="0" style={{border: 0}} allowFullScreen=""
                    loading="lazy"></iframe>
            </div>
        </div>
    </footer>
  );
}

export default Footer;
