import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import SectionImage from './components/sectionImage/sectionimage';
import SectionServicos from './components/sectionServicos/sectionservicos';
import SectionEspaco from './components/sectionEspaco/sectionespaco';
import SectionThemes from './components/sectionThemes/sectionThemes';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <>
              <Header />
              <SectionImage />
              <SectionServicos />
              <SectionEspaco />
              <SectionThemes />
              <Footer />
            </>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
