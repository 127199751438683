// src/components/Header/Header.js
import React, { useState } from 'react';
import './sectionespaco.css';

function SectionEspaco() {

    const [imageUrls, setImageUrls] = useState([
        'https://casasonhosfestas.s3.amazonaws.com/imagens/brinquedos_1.jpeg',
        'https://casasonhosfestas.s3.amazonaws.com/imagens/area_games_1.jpeg',
        'https://casasonhosfestas.s3.amazonaws.com/imagens/labamba_mini_roda_gigante.jpeg',
        'https://casasonhosfestas.s3.amazonaws.com/imagens/tirolesa.jpeg',
        'https://casasonhosfestas.s3.amazonaws.com/imagens/area_baby.jpeg',
        'https://casasonhosfestas.s3.amazonaws.com/imagens/brinquedao.jpeg'
    ]);


    return (
        <section id="espaco" className="mt-2">
            <div className="row max-width">
                <h2 className="mb-4 text-center">AMBIENTES</h2>
                <div id="carouselGaleria" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        {imageUrls.map((url, index) => (
                            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                <img src={url} className="d-block w-100 carousel-img-size" alt={`Imagem ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                    <a className="carousel-control-prev" href="#carouselGaleria" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Anterior</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselGaleria" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Próximo</span>
                    </a>
                </div>

                <div className="row mt-3">
                    {imageUrls.map((url, index) => (
                        <div key={index} className={"col-md-2 d-none d-md-none d-lg-block"}>
                            <img src={url} className="img-fluid img-thumbnail" data-target="#carouselGaleria"
                                data-slide-to={`${index}`} alt={`Miniatura ${index + 1}`} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default SectionEspaco;
