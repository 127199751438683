// src/components/Header/Header.js
import React, { useState } from 'react';
import './sectionservicos.css';

function SectionServicos() {

    const [services, setImageUrls] = useState([
        {
            titulo: "Festa Infantil",
            descricao: "Sua festa infantil será inesquecível com nossos serviços. Temos opções para todos os gostos e temas.",
            icone: "fa-child"
        },
        {
            titulo: "Debutante (15 anos)",
            descricao: "Celebre esse momento especial com nossos serviços personalizados para debutantes.",
            icone: "fa-star"
        },
        {
            titulo: "Casamentos",
            descricao: "Torne o seu casamento perfeito conosco. Oferecemos serviços que atendem a todos os detalhes do seu grande dia.",
            icone: "fa-ring"
        },
        {
            titulo: "Formaturas",
            descricao: "Comemore a conclusão dessa etapa importante com nossos serviços de formatura. Faça desse momento algo memorável.",
            icone: "fa-graduation-cap"
        },
        {
            titulo: "Eventos Corporativos",
            descricao: "Impressione seus clientes e colaboradores com nossos serviços especializados em eventos corporativos.",
            icone: "fa-briefcase"
        },
        {
            titulo: "Bodas",
            descricao: "Celebre o amor e a união em grande estilo com nossos serviços para bodas. Personalização total para seu momento especial.",
            icone: "fa-ring"
        }
    ]);





    return (
        <section id="services" className="max-width">
            <h2 className="mb-4 destaque-texto">NOSSOS SERVIÇOS</h2>
            <div className="row">
                {services.map((obj, index) => (
                    <div key={index} className="col-md-4 mb-4 text-center">
                        <div className="servico">
                        <h5><i className={`fas ${obj.icone} icone `}></i>{obj.titulo}</h5>
                        <p>{obj.descricao}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default SectionServicos;
