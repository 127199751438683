import React, { useState, useEffect } from 'react';
import './sectionThemes.css';

function SectionThemes() {
  const [themes, setThemes] = useState([
    {
      titulo: 'Decoração Moana',
      tema: 'Moana',
      url: 'https://casasonhosfestas.s3.amazonaws.com/imagens/moana.jpeg',
    },
    {
      titulo: 'Decoração Mickey',
      tema: 'Mickey',
      url: 'https://casasonhosfestas.s3.amazonaws.com/imagens/mickey.jpeg',
    },
    {
      titulo: 'Decoração Bela e a fera',
      tema: 'Bela e a fera',
      url: 'https://casasonhosfestas.s3.amazonaws.com/imagens/bela_e_fera.jpeg',
    },
    {
      titulo: 'Decoração Monstros S/A',
      tema: 'Monstros S/A',
      url: 'https://casasonhosfestas.s3.amazonaws.com/imagens/monstros_sa.jpeg',
    }
  ]);

  const [selectedTheme, setSelectedTheme] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const openModal = (theme) => {
    setSelectedTheme(theme);
  };

  const closeModal = () => {
    setSelectedTheme(null);
  };

  const goToPrevSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? themes.length - 1 : prevIndex - 1));
  };

  const goToNextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex === themes.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const handleResize = () => {
      // Atualizar o número de itens a serem exibidos com base no tamanho da tela
      const itemsToShow = window.innerWidth < 768 ? 1 : 3;
      setActiveIndex((prevIndex) => Math.min(prevIndex, themes.length - itemsToShow));
    };

    // Adicionar ouvinte de redimensionamento para ajustar o número de itens ao alterar o tamanho da tela
    window.addEventListener('resize', handleResize);
    handleResize(); // Chamar a função ao montar o componente

    return () => {
      // Remover o ouvinte de redimensionamento ao desmontar o componente
      window.removeEventListener('resize', handleResize);
    };
  }, [themes.length]);

  return (
    <section id="themes" className="max-width">
      <h2 className="mb-4 destaque-texto">DECORAÇÕES</h2>

      <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          {themes.map((theme, index) => (
            <div className={`carousel-item ${index === activeIndex ? 'active' : ''}`} key={index}>
              <div className="row">
                {[...Array(3)].map((_, i) => {
                  const currentIndex = (index + i) % themes.length;
                  const currentTheme = themes[currentIndex];
                  return (
                    <div className="col-md-4 mb-2" key={i}>
                      <div className="decoracao">
                        <h5>{currentTheme.titulo}</h5>
                        <div className="position-relative">
                          <img
                            src={currentTheme.url}
                            alt={currentTheme.tema}
                            className="imagem-decoracao"
                            data-toggle="modal"
                            data-target={`#modal${themes.indexOf(currentTheme)}`}
                            onClick={() => openModal(currentTheme)}
                          />
                          <div className="zoom-icon">
                            <i className="fas fa-search-plus"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Botão Anterior */}
      <div className="row mt-1">
        <div className="col-md-2 col-sm-2 col-6">
          <button className="carousel-button prev-button" onClick={goToPrevSlide}>
            Anterior
          </button>
        </div>

        <div className="col-md-2 col-sm-2 col-6" style={{'textAlign': 'right'}}>
          <button className="carousel-button next-button" onClick={goToNextSlide}>
            Próximo
          </button>
        </div>
      </div>

      {/* Modal */}
      {selectedTheme && (
        <div
          className="modal fade"
          id={`modal${themes.indexOf(selectedTheme)}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby={`modal${themes.indexOf(selectedTheme)}Label`}
          aria-hidden="true"
          onClick={closeModal}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id={`modal${themes.indexOf(selectedTheme)}Label`}>
                  {selectedTheme.titulo}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Fechar" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <img src={selectedTheme.url} alt={selectedTheme.tema} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default SectionThemes;
