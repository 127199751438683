// src/components/Header/Header.js
import React from 'react';
import './sectionimage.css';

function SectionImage() {

    const openExternalLink = () => {
        const externalLink = 'https://wa.me/5521990426814?text=Olá,%20gostaria%20de%20mais%20informações';
        window.open(externalLink, '_blank');
    };
    
    return (
        <section id="home">
            <h1 className="max-width">Descubra a Magia nas Nossas Festas! </h1>
            <p className="max-width">Há anos, temos o privilégio de ser parte de momentos inesquecíveis na vida de nossos
                clientes. Com dedicação e paixão, a nossa casa de festas tornou-se um símbolo de celebração, onde cada
                evento é tratado com o cuidado e a atenção que merece.</p>
            <button className="whatsapp-btn-home"
                onClick={openExternalLink}>
                <i className="fab fa-whatsapp"></i> Solicitar orçamento
            </button>
        </section>
    );
}

export default SectionImage;
