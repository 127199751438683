import React from 'react';
import icon from '../../assets/images/icone_192.png';
import './header.css'; // Importe o arquivo de estilos

const Header = () => {
  return (
    <header>
      <div className="brand">
        <img src={icon} alt="Casa dos sonhos festas" className="logo" />
      </div>
    </header>
  );
};

export default Header;
